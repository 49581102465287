import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";

import { Autoplay } from "swiper";

const Products = () => {
  const config = require("../../data/commentData.json");
  const products = config.map((e: any) => {
    return (
      <SwiperSlide key={config.indexOf(e)}>
        <div className="mx-auto">
          <p
            data-aos="fade-left"
            className="text-[14px] leading-[1.6rem] md:text-[20px] font-[300]"
          >
            {e.text}
          </p>
          <div className="mt-[2rem] text-center w-full text-[16px] md:text-[20px]">
            <span>
              <p className="font-[600]">{e.user}</p>
              <p className="font-[200]">{e.userPosition}</p>
            </span>
          </div>
        </div>
      </SwiperSlide>
    );
  });

  return (
    <div className="relative z-10 lg:container">
      <h1 className="rotate-180 w-full text-right font-serif text-[100px] h-max font-black leading-[5rem] text-orange">
        ,,
      </h1>
      <div data-aos="fade-up" className="">
        <Swiper
          slidesPerView={1}
          grabCursor={true}
          spaceBetween={30}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          loop={true}
          centeredSlides={true}
          className="mySwiper md:w-[600px] w-[300px] mx-auto"
        >
          {products}
        </Swiper>
      </div>
    </div>
  );
};

export default Products;
