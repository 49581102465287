import * as React from "react";
import Flickity from "react-flickity-component";

import { KeystoneImage } from "@/components/gatsby-image-keystone";
import { setGlobalState } from "@/state";
import { Link } from "gatsby";

import "@/styles/flickity.css";
// import "flickity/css/flickity.css";

const flickityOptions = {
  initialIndex: 0,
  dragThreshold: 10,
  cellAlign: "left",
  arrowShape: {
    x0: 10,
    x1: 60,
    y1: 50,
    x2: 75,
    y2: 50,
    x3: 30,
  },
  stroke: "#fff",
  wrapAround: true,
};

const Projects = (data: any) => {
  const projects = data.data.map((e: any) => {
    return (
      <div
        key={e.id}
        className="mx-[1rem] group min-h-[400px] h-full max-w-[230px] overflow-hidden rounded-r-lg border-l-[3px] border-orange bg-white dark:bg-gray"
      >
        <div className="flex justify-evenly min-h-[140px] rounded-tr-lg max-h-[140px] overflow-hidden">
          <div className="h-[100px] w-full group-hover:scale-110 duration-300 ease-in-out bg-orange">
            <Link
              to="/our-services/portfolio"
              onClick={() => {
                setGlobalState("projects", e.title);
              }}
            >
              {e.featureImage && (
                <KeystoneImage
                  baseUrl="steppelink"
                  image={{
                    alt: e.featureImage.id,
                    width: 100,
                    height: 70,
                    key: `${e.featureImage.id}.${e.featureImage.extension}`,
                  }}
                  layout="fullWidth"
                  alt={e.featureImage.id}
                />
              )}
            </Link>
          </div>
        </div>
        <div className="p-[7%]">
          <h1 className="text-[1rem] font-[700]  leading-[1.1rem]">
            {e.title}
          </h1>
          <div className="pt-[0.7rem]">
            <p className="md:text-[14px]">
              {e.content.document[0].children[0].text}
            </p>
          </div>
        </div>
        <div className="absolute bottom-0 right-0">
          <Link
            to="/our-services/portfolio"
            onClick={() => {
              setGlobalState("projects", e.title);
            }}
          >
            <button className="hover:text-greyDark duration-50 relative ml-4 h-[2.3rem] w-[2rem] rounded-tl-lg rounded-br-lg border-2 border-solid border-orange text-[1.2rem] font-[100] ease-out hover:bg-orange hover:ease-in active:border-orangeDarkDark">
              &#62;
            </button>
          </Link>
        </div>
      </div>
    );
  });
  return (
    <div className="relative z-10">
      <h1 data-aos="fade-up" className="mb-[40px]">
        Portfolio
      </h1>

      <div data-aos="fade-up" className="">
        <Flickity
          className="carousel overflow-hidden" // default ''
          elementType={"div"} // default 'div'
          options={flickityOptions} // takes flickity options {}
          reloadOnUpdate // default false
          static // default false
        >
          {projects}
        </Flickity>
      </div>
    </div>
  );
};

export default Projects;
