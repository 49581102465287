import React from "react";
import Layout from "@/components/Layout";
import Technology from "@/components/Home/Technology";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Projects from "@/components/Home/Projects";
import Products from "@/components/Home/Products";
import Team from "@/components/Home/Team";
import { setGlobalState, useGlobalState } from "@/state";

export default function Home({ data }: any) {
  return (
    <Layout title="Home" ContactUsBtn={false}>
      <div className="box lg:container px-[20px] xl:px-0 relative">
        <div
          data-aos="fade-right"
          className="pt-[4rem] md:pt-[7rem] relative z-10 flex flex-col items-center sm:items-start"
        >
          <h1
            data-aos="fade-up"
            className="box md:text-[45px] sm:text-left text-center sm:text-[40px] text-[35px] lg:text-[55px] font-[300] leading-[3rem] lg:leading-[4rem] relative z-10"
          >
            Let us make <br />
            <span className="md:text-[45px] sm:text-[40px] text-[35px] lg:text-[55px]  font-[700]">
              OUR PASSION
            </span>
            <br />
            <span
              data-sal="fade"
              className="md:text-[45px] sm:text-[40px] text-[35px] lg:text-[55px]  font-[700] text-orange"
            >
              YOUR BUSINESS
            </span>
          </h1>

          <p className="font-[200] sm:w-[50%] my-[2rem] relative z-10 sm:text-left text-center">
            Professional team made up of the best engineers in Mongolia with
            extensive experience in the field of IT.
          </p>

          <Link to="/contact-us" className="">
            <button className="rounded-full bg-orange font-[700] px-[3rem] text-white py-[1rem] hover:bg-orangeDark relative z-10">
              CONTACT US
            </button>
          </Link>
        </div>

        <StaticImage
          src="../static/robot-1.png"
          width={430}
          placeholder="none"
          alt="Robot Picture"
          data-aos="fade-left"
          className="max-w-[430px] w-full mx-auto opacity-30 sm:opacity-100 !absolute sm:right-0 right-[50%] translate-x-[50%] sm:translate-x-0 top-0 z-0"
        />
      </div>

      {/* Logos */}
      <div
        data-aos="fade-up"
        className="mx-auto mt-[4rem] !flex w-[80%] !flex-wrap !justify-center md:z-10 md:w-[100%]"
      >
        <span className="text-center md:inline-block md:align-middle">
          <p className="md:mr-[1rem] my-auto md:inline-block md:align-middle">
            Trusted by:
          </p>
          <a
            href="https://steppearena.mn"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              placeholder="tracedSVG"
              width={98}
              className={`trustedBy m-[0.6rem] md:mx-[1.7rem] md:align-middle dark:brightness-100 brightness-0`}
              src="../static/logos/Steppe-arena-1.png"
              alt="Steppe Arena"
            />
          </a>
          <a href="https://esan.mn" target="_blank" rel="noreferrer noopener">
            <StaticImage
              placeholder="tracedSVG"
              width={98}
              className={`trustedBy m-[0.6rem] md:mx-[1.7rem] md:align-middle dark:brightness-100 brightness-0`}
              src="../static/logos/esan-1.png"
              alt="Esan"
            />
          </a>
          <a
            href="https://www.mesa.mn"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              placeholder="tracedSVG"
              width={110}
              className={`trustedBy m-[0.6rem] md:mx-[1.7rem] md:align-middle dark:brightness-100 brightness-0`}
              src="../static/logos/Mesa-2.png"
              alt="MESA"
            />
          </a>
          <a
            href="https://www.facebook.com/MongolianSportsCorporation/?_rdr"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              placeholder="tracedSVG"
              width={77}
              className={`trustedBy m-[0.6rem] md:mx-[1.7rem] md:align-middle dark:brightness-100 brightness-0`}
              src="../static/logos/MSC-1.png"
              alt="Mongolian Sports Corporation"
            />
          </a>
          <a
            href="https://www.odintech.app"
            className="trustedBy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <StaticImage
              placeholder="tracedSVG"
              width={85}
              className={`trustedBy m-[0.6rem] md:mx-[1.7rem] md:align-middle brightness-100`}
              src="../static/logos/Odin-1.png"
              alt="ODIN"
            />
          </a>
        </span>
      </div>

      {/* Services */}
      <div className="lg:container px-[20px] xl:px-0 md:mt-[80px] mt-[50px] lg:mt-[100px]">
        <h1 data-aos="fade-up" className="mb-[4rem]">
          Services
        </h1>

        {/* System Development */}
        <div className="sm:grid flex flex-col sm:grid-cols-2 gap-[1rem]">
          <div
            data-aos="fade-up"
            className="relative bg-white dark:bg-gray max-h-[600px] h-full z-10 px-[40px] pb-[20px] "
          >
            <div className="mb-[2rem]">
              <StaticImage
                placeholder="tracedSVG"
                src="../static/services/Ellipse.png"
                className="!absolute top-0 left-[50%] max-w-[450px] -translate-x-[50%] z-0 w-full"
                width={450}
                alt="ellipse"
              />
              <StaticImage
                className="z-0 w-[80%] lg:w-[300px] left-[50%] -translate-x-[50%] top-[1rem]"
                src="../static/services/System.png"
                width={300}
                placeholder="tracedSVG"
                alt="ERP"
              />
            </div>

            <div>
              <h2 className="text-[22px] my-[1rem]">System Development</h2>
              <p className="">
                We will help define exactly what your organization wants through
                its website. It can also be a structured web application with a
                fully dynamic Content Management System (CMS) that can be used
                by anyone with any level of IT knowledge.
              </p>
            </div>

            <Link
              to="/our-services"
              onClick={() => {
                setGlobalState("services", "tab1");
              }}
            >
              <button className="border-[2px] absolute bottom-0 left-[40px] z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
                Learn more
              </button>
            </Link>

            <button className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
              Learn more
            </button>
          </div>

          {/* Mobile Development */}
          <div
            data-aos="fade-up"
            className="relative z-10  bg-white dark:bg-gray px-[40px] pb-[20px] max-h-[600px]"
          >
            <div className="relative mb-[2rem]">
              <StaticImage
                placeholder="tracedSVG"
                src="../static/services/Ellipse.png"
                width={450}
                className="!absolute top-0 left-[50%] max-w-[450px] -translate-x-[50%] z-0 w-full"
                alt="ellipse"
              />
              <StaticImage
                className="z-0 w-[80%] lg:w-[300px] left-[50%] -translate-x-[50%] top-[1rem]"
                src="../static/services/Mobile.png"
                width={300}
                placeholder="tracedSVG"
                alt="Mobile"
              />
            </div>

            <div>
              <h2 className="text-[22px] my-[1rem]">Mobile App Development</h2>
              <p className="md:text-[16px]">
                Mobile application development is the creation of software
                designed to work on mobile devices and optimized to leverage the
                unique features and hardware of these products.
              </p>
            </div>

            <Link
              to="/our-services"
              onClick={() => {
                setGlobalState("services", "tab2");
              }}
            >
              <button className="border-[2px] absolute bottom-0 left-[40px] z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
                Learn more
              </button>
            </Link>

            <button className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
              Learn more
            </button>
          </div>

          {/* Web Development */}
          <div
            data-aos="fade-up"
            className="relative z-10  bg-white dark:bg-gray px-[40px] pb-[20px] max-h-[600px]"
          >
            <div className="relative mb-[2rem]">
              <StaticImage
                placeholder="tracedSVG"
                src="../static/services/Ellipse.png"
                width={450}
                className="!absolute top-0 left-[50%] max-w-[450px] -translate-x-[50%] z-0 w-full"
                alt="ellipse"
              />
              <StaticImage
                className="z-0 w-[80%] lg:w-[300px] left-[50%] -translate-x-[50%] top-[1rem]"
                src="../static/services/Group.png"
                width={300}
                placeholder="tracedSVG"
                alt="ERP"
              />
            </div>

            <div>
              <h2 className="text-[22px] my-[1rem]">Web Development</h2>
              <p className="md:text-[16px]">
                Web development can range from the development of a single
                static plain text page to complex web applications, social
                networking services, and dynamic Content Management System (CMS)
                that can be used by anyone with any level of IT knowledge.
              </p>
            </div>

            <Link
              to="/our-services"
              onClick={() => {
                setGlobalState("services", "tab3");
              }}
            >
              <button className="border-[2px] absolute bottom-0 left-[40px] z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
                Learn more
              </button>
            </Link>

            <button className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
              Learn more
            </button>
          </div>

          {/* AI Development */}
          <div
            data-aos="fade-up"
            className="relative z-10  bg-white dark:bg-gray px-[40px] pb-[20px] max-h-[600px]"
          >
            <div className="relative mb-[2rem]">
              <StaticImage
                placeholder="tracedSVG"
                src="../static/services/Ellipse.png"
                width={450}
                className="!absolute top-0 left-[50%] max-w-[450px] -translate-x-[50%] z-0 w-full"
                alt="ellipse"
              />
              <StaticImage
                className="z-0 w-[80%] lg:w-[300px] left-[50%] -translate-x-[50%] top-[1rem]"
                src="../static/services/AI.png"
                width={300}
                placeholder="tracedSVG"
                alt="ERP"
              />
            </div>

            <div>
              <h2 className="text-[22px] my-[1rem]">AI Development</h2>
              <p className="md:text-[16px]">
                Artificial intelligence developers build AI functionality into
                software applications by integrating and implementing AI
                algorithms and logic into the deliverables of an IT project.
              </p>
            </div>

            <Link
              to="/our-services"
              onClick={() => {
                setGlobalState("services", "tab4");
              }}
            >
              <button className="border-[2px] absolute bottom-0 left-[40px] z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
                Learn more
              </button>
            </Link>

            <button className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange">
              Learn more
            </button>
          </div>

          {/* Other Development */}
          <div
            data-aos="fade-up"
            className="relative z-10  bg-white dark:bg-gray px-[40px] mx-auto flex flex-col md:grid grid-cols-2 md:gap-[6rem] pb-[40px] md:col-span-2"
          >
            <div className="relative mb-[2rem] mx-auto lg:w-max">
              <StaticImage
                placeholder="tracedSVG"
                src="../static/services/Ellipse.png"
                width={450}
                className="max-w-[500px] !absolute top-0 left-[50%] w-full -translate-x-[50%]"
                alt="ellipse"
              />
              <StaticImage
                className="z-0 w-[80%] lg:w-[400px] left-[50%] -translate-x-[50%] top-[1rem]"
                src="../static/services/other.png"
                width={450}
                placeholder="tracedSVG"
                alt="ERP"
              />
            </div>
            <div className="relative md:mt-[40px]">
              <div>
                <h2 className="text-[22px] mb-[1rem]">Other Development</h2>
                <p className="md:text-[16px]">
                  Information technology consultation, hardware research, UI/UX
                  designing services aims to advise organizations on how to best
                  use information technology to achieve their business
                  objectives.
                </p>
              </div>
              <div className="md:block hidden min-h-[4rem]">
                <Link
                  to="/our-services"
                  onClick={() => {
                    setGlobalState("services", "tab5");
                  }}
                  className="border-[2px] absolute bottom-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange"
                >
                  Learn more
                </Link>

                <Link
                  to="/our-services"
                  className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange"
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className="md:hidden my-[2rem]">
              <Link
                to="/our-services"
                onClick={() => {
                  setGlobalState("services", "tab5");
                }}
                className="border-[2px] absolute bottom-0 left-[40px] z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange"
              >
                Learn more
              </Link>

              <Link
                to="/our-services"
                className="border-[2px] invisible bottom-0 left-0 z-10 border-orange my-[2rem] px-[23px] rounded-tl-lg rounded-br-lg hover:bg-orange"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Technology */}
      <div className="my-[100px]">
        <Technology />
      </div>

      {/* Projects */}
      <div className="lg:container px-[20px] xl:px-0">
        <Projects data={data.cms.projects} />
      </div>

      {/* Products */}
      <div className="lg:container px-[20px] xl:px-0 mt-[4rem]">
        <Products data={data.cms.products} />
      </div>

      {/* Team */}
      <div className="lg:container px-[20px] xl:px-0">
        <Team />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query HomePageQuery {
    cms {
      projects(
        where: { status: { equals: "published" } }
        orderBy: { order: asc }
      ) {
        title
        status
        id
        content {
          document
        }
        featureImage {
          extension
          filesize
          height
          id
          width
        }
      }
      products(
        where: { status: { equals: "published" } }
        orderBy: { order: asc }
      ) {
        title
        status
        id
        content {
          document
        }
        featureImage {
          extension
          filesize
          height
          id
          width
        }
      }
    }
  }
`;
