import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export default function Content() {
  return (
    <div className="text-center mt-[4rem] md:my-[8rem] md:mb-[4rem]">
      <h1
        data-aos="fade-up"
        className="mx-auto mb-[5%] text-center sm:w-[90%] w-full lg:w-[80%]"
      >
        Behind every great project is a
        <span className="text-orange"> great team </span>
      </h1>
      <p
        data-aos="fade-up"
        className="mx-auto mb-[4rem] text-[18px] font-[200] md:w-[80%] md:text-[22px]"
      >
        {" "}
        Good team work is a group of people who can communicate effectively and
        constructively to solve problems. It can strengthen and unify the
        company, project or department.
      </p>
      <div data-aos="fade-up" className="mb-[4rem]">
        <Link to="/join-us">
          <button className="rounded-full text-white bg-orange px-[4rem] py-[1rem] hover:bg-orangeDark relative z-10">
            JOIN US
          </button>
        </Link>
      </div>

      <div className="flex sm:flex-row flex-col sm:justify-between gap-[2rem]">
        <div
          data-aos="fade-right"
          className="rounded-xl overflow-hidden  border-[2px] border-orange"
        >
          <StaticImage
            width={350}
            height={350}
            // data-aos="fade-right"
            placeholder="blurred"
            className=" mb-[3rem] md:mb-0 border-orange md:align-middle h-full"
            src="../../static/team/Rectangle-7.jpeg"
            alt="Steppe Arena"
          />
        </div>
        <div
          data-aos="fade-up"
          className="rounded-xl overflow-hidden  border-[2px] border-orange"
        >
          <StaticImage
            width={350}
            height={350}
            placeholder="blurred"
            className=" mb-[3rem] md:mb-0 border-orange md:align-middle h-full"
            src="../../static/team/Rectangle-8.jpeg"
            alt="Steppe Arena"
          />
        </div>
        <div
          data-aos="fade-left"
          className="rounded-xl overflow-hidden  border-[2px] border-orange"
        >
          <StaticImage
            width={350}
            height={350}
            placeholder="blurred"
            className=" mb-[3rem] md:mb-0 border-orange md:align-middle h-full"
            src="../../static/team/Rectangle-9.jpeg"
            alt="Steppe Arena"
          />
        </div>
      </div>
    </div>
  );
}
