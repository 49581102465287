import React from "react";
import Comments from "./Comment";
import { StaticImage } from "gatsby-plugin-image";

export default function Content() {
  return (
    <div className="z-0 !relative mb-[4rem] flex md:static">
      <div className="opacity-50 xl:opacity-100">
        <StaticImage
          placeholder="tracedSVG"
          className="z-0 !absolute select-none left-0 xl:-mt-[25%] xl:w-[60%]"
          src="../../static/Yellow-Ellipse-1.png"
          alt="Yellow ellipse"
        />
        <span className="">
          <StaticImage
            placeholder="tracedSVG"
            width={500}
            className="z-0 !absolute select-none left-[15%] mt-[30%] w-[50%] xl:left-[10%] xl:mt-[10%] xl:w-[30%] lg:mt-0"
            src="../../static/Ellipse-1.png"
            alt="White ellipse"
          />
          <StaticImage
            placeholder="tracedSVG"
            width={800}
            className="z-0 !absolute select-none left-0 mt-[5rem] xl:mt-0 xl:w-[60%]"
            src="../../static/Robot-2.png"
            alt="Robot 2"
          />
        </span>
      </div>
      <div className="lg:container px-[20px] xl:px-0">
        <div className="relative z-10 mt-[8%] xl:ml-[45%]">
          <div>
            <h2
              data-aos="fade-left"
              className="mb-[8%] text-center text-[40px] leading-[3rem] md:w-[100%] md:text-left md:text-[50px] md:leading-[4rem]"
            >
              Only the
              <span className="text-orange"> best and latest </span>
              technology
            </h2>
          </div>
          <div className="!lg:inline-block mb-[7rem] w-[100%] !flex-wrap">
            <span
              data-aos="fade-left"
              className="!flex-wrap flex gap-[2rem] md:align-middle justify-center md:justify-start items-center"
            >
              <div className="my-auto">
                <StaticImage
                  width={70}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/react.png"
                  alt="React"
                />
              </div>
              <div className="my-auto">
                <StaticImage
                  width={120}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/Node-Js.png"
                  alt="Node JS"
                />
              </div>
              <div>
                <StaticImage
                  width={110}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/java.png"
                  alt="Java"
                />
              </div>
              <div className="my-auto">
                <StaticImage
                  width={150}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/Python_logo_and_wordmark.png"
                  alt="Python"
                />
              </div>
              <div>
                <StaticImage
                  width={80}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/Sql_data_base_with_logo.png"
                  alt="SQL"
                />
              </div>
              <div className="my-auto">
                <StaticImage
                  width={120}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/swift.png"
                  alt="Swift"
                />
              </div>
              <div>
                <StaticImage
                  width={90}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/nextJS.png"
                  alt="NextJS"
                />
              </div>
              <div>
                <StaticImage
                  width={140}
                  placeholder="tracedSVG"
                  className={`my-[0.8rem] max-w-[150px] mx-[1rem] brightness-0 md:mr-[1.8rem] md:align-middle dark:invert
                  }`}
                  src="../../static/languages/kotlin.png"
                  alt="Kotlin"
                />
              </div>
              <div>
                <StaticImage
                  width={150}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/GraphQL.png"
                  alt="GraphQL"
                />
              </div>
              <div>
                <StaticImage
                  width={100}
                  placeholder="tracedSVG"
                  className={`dark:brightness-100 brightness-0`}
                  src="../../static/languages/flutter.png"
                  alt="Flutter"
                />
              </div>
              <div>
                <h2>and more...</h2>
              </div>
            </span>
          </div>
          <div>
            <h2
              data-aos="fade-left"
              className="mb-[8%] text-center text-[40px] leading-[3rem] lg:w-[100%] md:text-left md:text-[50px] md:leading-[4rem]"
            >
              What
              <span className="text-orange"> our clients </span>
              say about us
            </h2>
            <div>
              <Comments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
